.App {
  text-align: center;
  background-color: #fffafa;
  min-height: 100vh;
}

/* main */
.main {
  padding-top: 25vh;
  padding-left: 5vw;
  min-height: 40vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.image-container {
  margin: 0 auto;
}

.main-content {
  margin-left: 100px;
  margin-right: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
}

/* icons styling */

.linkedin-icon {
  color: #487eb0;
  font-size: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
}

.youtube-icon {
  color: #e74c3c;
  font-size: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
}

.youtube-icon:hover {
  color: #d63031;
}

.github-icon {
  color: #718093;
  font-size: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
}

.github-icon:hover {
  color: #353b48;
}

.ig-icon {
  color: #fab1a0;
  font-size: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
}

.ig-icon:hover {
  color: #e17055;
}

.iot-icon {
  color: #ffeaa7;
  font-size: 3.5em;
  margin-left: 10px;
  margin-right: 10px;
}

.iot-icon:hover {
  color: #fdcb6e;
}


.external-links {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

@keyframes fade {
  0%   {opacity:0}
  33.333% { opacity: 0}
  66.666% { opacity: 0}
  100% { opacity: 1}
}

/* Navbar edits */

.navbar-icon {
  margin-left: 10px;
  margin-top: 15px;
  margin-right: -30px;
}

.navbar-brand {
  padding-top: 10px;
  padding-left: 30px;
  height: 80px;
  line-height: 80px;
  font-size: 2.5em;
  /* color: #2f3640; */
}

.main-title {
  display: flex;
  flex-wrap: wrap;
  font-size: 3em;

  /* color: #2f3640; */
}



.main-description {
  display: flex;
  flex-wrap: wrap;
}
/* Me Icon */

.me-icon {
  min-width: 325px;
  max-height: 50vh;
}
